import React, { useState } from "react";

import { Container, Button, P } from "@util/standard";
import { useStore } from "@state/store";
import AccountTemplate from "@components/account/accountTemplate";
import { useBlogQuery } from "@api";
import { Button as CustomButton } from "@global";
import BlogPreview from "@components/blogs/blogPreview";

export default function Account() {
  const { shopify, savedBlogs } = useStore();
  const [shownNumber, setShownNumber] = useState(15);
  const { allSanityBlog } = useBlogQuery();

  if (!savedBlogs || savedBlogs.length < 1) {
    return (
      <AccountTemplate shopify={shopify} handle="blogs">
        <Container
          flexDirection="column"
          alignItems="center"
          width="100%"
          padding="50px 0"
        >
          <P>You have no Saved Blog</P>
          <CustomButton
            theme="transparent"
            linkTo="/blogs"
            text="VIEW Blogs"
          />
        </Container>
      </AccountTemplate>
    );
  }

  if (!allSanityBlog?.nodes) {
    return (
      <AccountTemplate shopify={shopify} handle="blogs">
        <P color="errorRed">Error retrieving blogs.</P>
      </AccountTemplate>
    );
  }

  const blogs = allSanityBlog.nodes.filter(blog => {
    return savedBlogs.includes(blog.id);
  });

  return (
    <AccountTemplate shopify={shopify} handle="blogs">
      <Container
        display="inline-flex"
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
        margin="0px auto 30px 0px"
      >
        {blogs.slice(0, shownNumber).map(blog => {
          return <BlogPreview blog={blog} key={blog._id} hasMarginBottom />;
        })}
        {shownNumber < blogs.length && (
          <Container width="100%" margin="0 0 100px" tabletMargin="0 0 55px">
            <Button
              onClick={() => {
                setShownNumber(pre => pre + 15);
              }}
            >
              Load More
            </Button>
          </Container>
        )}
      </Container>
    </AccountTemplate>
  );
}
